
        Component.define('header-internal', (el, params) => {
            /**
 * Each component receives the variables explained below
 *
 * @param {HTMLElement} el - Component's main DOM element
 * @param {Object} params - Params to be used
 */


        });