
var Timeline = {
    setup: function(c) {
        //Scene 1
        var animation1 = new TimelineMax()
            .add([
                TweenMax.fromTo(".bg-header", 1, {y: '0%'}, {y: '50%', ease: Linear.easeNone})
            ]);

        var scene = new ScrollMagic.Scene({
                triggerElement: ".bg-header",
                triggerHook: "onLeave",
                duration: "100%"
            })
            .setTween(animation1)
            .addTo(c);

        //Scene 2
        var duration2 = $('.manifest').innerHeight();
        var animation2 = new TimelineMax()
            .add([
                TweenMax.fromTo(".manifest .bg", 1, {y: '-50%'}, {y: '0%', ease: Linear.easeNone})
            ]);

        var scene = new ScrollMagic.Scene({
                triggerElement: ".manifest",
                triggerHook: "onEnter",
                duration: duration2
            })
            .setTween(animation2)
            .addTo(c);

        //Scene 3
        var duration3 = $('.designer').innerHeight();
        var animation3 = new TimelineMax()
            .add([
                TweenMax.fromTo(".designer .photo", 1, {y: '-50%'}, {y: '0%', ease: Linear.easeNone}),
                TweenMax.fromTo(".designer .lines-01", 1, {x: '-50%'}, {x: '0%', ease: Linear.easeNone}),
                TweenMax.fromTo(".designer .lines-02", 1, {x: '50%'}, {x: '0%', ease: Linear.easeNone})
            ]);

        var scene = new ScrollMagic.Scene({
                triggerElement: ".designer",
                triggerHook: "onEnter",
                duration: duration3
            })
            .setTween(animation3)
            .addTo(c);
    }
};
