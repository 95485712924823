/**
 * Main code
 */

!(function(window, document, undefined){
    'use strict';

    let $window     = $(window);
    let $document   = $(document);
    let $body       = $('body');
    let wW          = $window.width();
    let wH          = $window.height();

    let canScroll;
    let scrollPos = $window.scrollTop();
    let scrollAni = false;
    let scrollingTo  = false;

    if (Is.mobile.ANY()) {
        $body.addClass('mobile');
    } else {
        $body.addClass('desktop');
    }

    if (Is.desktop.MAC()) {
        $body.addClass('mac');
    }

    $document.on('preloaded.after', function () {
        $window.trigger('scroll');
    });

    $document.on('preloaded.before', function () {
        if (!Is.mobile.ANY()) {
            var c = new ScrollMagic.Controller();
            Timeline.setup(c);
        }

        $('[data-page-link]').on('click', function(){
            var target = $(this).attr('data-page-link');

            if (target == 'home') {
                TweenMax.to(window, .8, {scrollTo: {y: 0, autoKill: false}, ease: Power2.easeOut});
            } else {
                var pos = $('[data-page="'+target+'"]').offset().top;

                TweenMax.to(window, .8, {scrollTo: {y: pos, autoKill: false}, ease: Power2.easeOut});
            }

        });

        $('.c-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.menu-mobile').toggleClass('show');
        });

        $('.menu-mobile a').on('click', function(){
            $('.menu-mobile').removeClass('show');
            $('.c-hamburger').removeClass('is-active');
        });

        $window
            .on('mousewheel DOMMouseScroll', function(event){
                if (!canScroll || scrollingTo) {
                    event.preventDefault();
                    return false;
                }

                if (!$body.is('.mac') && !Is.mobile.ANY() && !$body.is('.modal-opened')) {
                    event.preventDefault();

                    let maxScroll = $(document).height() - $(window).height();
                    let delta = event.originalEvent.wheelDelta / 120 || -event.originalEvent.detail / 3;
                    scrollPos = scrollPos - parseInt(delta * 50);
                    scrollPos = scrollPos < 0 ? 0 : scrollPos;
                    scrollPos = scrollPos > maxScroll ? maxScroll : scrollPos;

                    let onStopScroll = function() {
                        scrollAni = false;
                    };

                    TweenMax.killTweensOf(this);
                    TweenMax.to(this, .8, {scrollTo : {y: scrollPos}, ease: Power2.easeOut, onStart: function() { scrollAni = true; }, onComplete: onStopScroll});
                }
            })
            .on('scroll', function () {
                var posScroll = $window.scrollTop();

                if (!scrollAni) {
                    scrollPos = $window.scrollTop();
                }

                if (!Is.mobile.ANY()) {
                    Animator.onScroll();
                }
            })
            .on('resize', function () {
                wW = $window.width();
                wH = $window.height();

                if (!Is.mobile.ANY()) {
                    $body.removeClass('mobile');
                    $body.addClass('desktop');
                    canScroll = true;
                } else {
                    $body.removeClass('desktop');
                    $body.addClass('mobile');
                    canScroll = false;
                }
            });

        Component.init();

        $window.trigger('resize');
    });


    $body.waitForImages(true)
        .progress((loaded, count, success) => {
            count++;

            let percent = (loaded * 100 / count);

            TweenMax.to('.preloader__bar__progress', .6, { width: percent+'%', ease: Expo.easeInOut});
        })
        .done(() => {
            TweenMax.to('.preloader__bar__progress', .6, { width: '100%', onComplete: () => {
                $document.trigger('preloaded.before');

                TweenMax.to('.preloader', .4, { opacity: 0, display: 'none', onComplete: () => $document.trigger('preloaded.after') });
            } });
        });
})(window, document, undefined);
